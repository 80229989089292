import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import { Helmet } from "react-helmet";

const propTypes = {
	/** Page title */
	title: PropTypes.string,
	/** Page Description */
	description: PropTypes.string,
	/** Hero image url – if available */
	image: PropTypes.string,
	/** To include slugs */
	pathname: PropTypes.string,
	/** Inline boolean prop, just include [article] in the SEO tag if its an article page */
	article: PropTypes.bool,
};

const defaultProps = {
	title: null,
	description: null,
	image: null,
	pathname: null,
	article: false,
};

function SEO({ title, description, image, article }) {
	const path = useLocation();
	const { pathname } = path;

	const data = useStaticQuery(query);
	const {
		site: {
			siteMetadata: { defaultTitle, titleTemplate, defaultDescription, defaultImage, url },
		},
	} = data || {};

	const seoProps = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		image: image ? `${url}${image}` : `${url}${defaultImage}`,
		url: `${url}${pathname || "/"}`,
	};

	return (
		<Helmet title={seoProps.title} titleTemplate={title && titleTemplate} defer={false}>
			{seoProps.title && <meta property="og:title" content={seoProps.title} />}
			{seoProps.title && <meta name="twitter:title" content={seoProps.title} />}

			<meta property="og:type" content="website" />

			<meta name="description" content={seoProps.description} />
			{seoProps.description && <meta property="og:description" content={seoProps.description} />}
			{seoProps.description && <meta name="twitter:description" content={seoProps.description} />}

			<meta name="image" content={seoProps.image} />
			<meta name="twitter:card" content="summary_large_image" />
			{seoProps.image && <meta property="og:image" content={seoProps.image} />}
			{seoProps.image && <meta name="twitter:image" content={seoProps.image} />}

			{seoProps.url && <meta property="og:url" content={seoProps.url} />}
			{(article ? true : null) && <meta property="og:type" content="article" />}
		</Helmet>
	);
}

SEO.propTypes = propTypes;
SEO.defaultProps = defaultProps;

const query = graphql`
	query SEO {
		site {
			siteMetadata {
				defaultTitle
				titleTemplate
				defaultDescription
				url: siteUrl
				defaultImage
			}
		}
	}
`;

export default SEO;
