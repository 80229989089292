/* eslint-disable import/prefer-default-export */

/**
 * returns transition animation for header modules (logo, time, email, etc)
 * @param {bool} startAnim    trigger for animation
 * @param {num} delay         delay value
 */
export function headerTransitions(startAnim = true, delay = 0) {
	return {
		initial: {
			y: -10,
			opacity: 0,
		},
		animate: {
			y: startAnim && 0,
			opacity: startAnim ? 1 : 0,
		},
		transition: {
			delay,
			ease: [0.33, 1, 0.68, 1],
			duration: 1.2,
		},
	};
}
