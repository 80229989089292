/**
 *  Determine lowest common number denomination
 * @param {num} numerator
 * @param {num} denominator
 */
export function reduce(numerator, denominator) {
	let gcd = function gcd(a, b) {
		return b ? gcd(b, a % b) : a;
	};
	gcd = gcd(numerator, denominator);
	return [numerator / gcd, denominator / gcd];
}

/**
 * Determine specs of plane object
 */
export function determinePlaneSize() {
	let screenRatio = reduce(window.innerWidth, window.innerHeight);
	screenRatio = screenRatio[0] / screenRatio[1];

	const primaryAxis = screenRatio > 1 ? window.innerWidth : window.innerHeight;
	const multiplier = screenRatio < 1 ? 0.3 : 0.18;
	const dimensions = primaryAxis * multiplier;

	// Determine segments
	const segments = Number((primaryAxis / 8).toFixed(0));

	return {
		dimensions,
		segments,
	};
}

/**
 *
 * @param {obj} param0
 */
export function mouseFromCenter({ x, y }) {
	let calcX = 0;
	let calcY = 0;

	if (x) {
		calcX = ((x - window.innerWidth / 2) / window.innerWidth) * 2;
	}
	if (y) {
		calcY = ((y - window.innerHeight / 2) / window.innerHeight) * 2;
	}

	return {
		x: calcX,
		y: calcY,
	};
}
