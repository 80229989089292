import React, { PureComponent, createRef } from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";

import Header from "../../components/Header/Header";
import Background from "../../components/Background/Background";
import EmailCTA from "../../components/EmailCTA/EmailCTA";
import Scrollbar from "../../components/Scrollbar/Scrollbar";
import SEO from "../../components/SEO/SEO";
import { ContextWrappers } from "../../components/ContextWrappers/ContextWrappers";
import Preloader from "../../components/Preloader/Preloader";

import styles from "./Main.module.scss";

const propTypes = {
	/** Default react prop for child refs */
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	/** Flag to determine if canvas and scroller should be rendered */
	CMSPreviewMode: PropTypes.bool,
	/** Flag to determine if SEO component should be included in the render */
	includeSEO: PropTypes.bool,
	/** Built-in react object */
	path: PropTypes.string,
};

const defaultProps = {
	children: {},
	CMSPreviewMode: false,
	includeSEO: true,
	path: "",
};

export default class Main extends PureComponent {
	appMainRef = createRef();

	childrenWrapperRef = createRef();

	render() {
		const { path, includeSEO, children, CMSPreviewMode } = this.props;

		// NOTE:
		// Canvas element seems to cause Netlify CMS preview page to crash.
		// Disable it while in preview mode
		return (
			<Preloader CMSPreviewMode={CMSPreviewMode}>
				<main className={styles.main__wrapper} ref={this.appMainRef}>
					<ContextWrappers mainRef={this.appMainRef}>
						{includeSEO && <SEO />}

						<section className={styles.canvas__wrapper}>
							{!CMSPreviewMode && <Background />}
						</section>

						{!CMSPreviewMode && (
							<>
								<Header mainRef={this.appMainRef} />
								<EmailCTA />
							</>
						)}

						{CMSPreviewMode ? (
							<section className={styles.children__wrapper} ref={this.childrenWrapperRef}>
								{children}
							</section>
						) : (
							<AnimatePresence
								exitBeforeEnter
								onExitComplete={() => {
									this.appMainRef.current.scrollTop = 0;
								}}
							>
								<motion.div
									key={path}
									initial={{ y: 15, opacity: 0 }}
									animate={{ y: 0, opacity: 1 }}
									exit={{ opacity: 0 }}
									transition={{
										duration: 0.65,
										delay: 0.15,
									}}
									style={{ height: "100%" }}
								>
									<section className={styles.children__wrapper} ref={this.childrenWrapperRef}>
										{children}
									</section>
								</motion.div>
							</AnimatePresence>
						)}

						{!CMSPreviewMode && (
							<Scrollbar
								path={path}
								mainRef={this.appMainRef}
								contentRef={this.childrenWrapperRef}
							/>
						)}
					</ContextWrappers>
				</main>
			</Preloader>
		);
	}
}

Main.propTypes = propTypes;
Main.defaultProps = defaultProps;
