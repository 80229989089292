import React, { useContext } from "react";
import Obfuscate from "react-obfuscate";
import { motion } from "framer-motion";

import { PreloaderContext } from "../Preloader/Preloader";

import styles from "./EmailCTA.module.scss";
import { headerTransitions } from "../../utils";

function EmailCTA() {
	const { isPreloaderComplete } = useContext(PreloaderContext);

	return (
		<motion.section className={styles.email__wrapper} {...headerTransitions(isPreloaderComplete)}>
			<Obfuscate
				email="wklsh@hotmail.sg"
				headers={{ subject: "Hello!" }}
				className={styles.email__text}
			>
				E-mail
			</Obfuscate>
		</motion.section>
	);
}

export default EmailCTA;
