import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { useLocation, navigate } from "@reach/router";

import { ScrollEventsContext } from "../ContextWrappers/ContextWrappers";
import { PreloaderContext } from "../Preloader/Preloader";
import { headerTransitions } from "../../utils";

import styles from "./Header.module.scss";

const propTypes = {
	mainRef: PropTypes.object.isRequired,
};

const animation = (variant, isTriggered) => {
	let initialProp;
	let animateProp;
	let delayProp;
	let durationProp;

	if (variant === "primary") {
		initialProp = { y: "0%" };
		animateProp = { y: isTriggered ? "-200%" : "0%" };
		delayProp = isTriggered ? 0 : 0.2;
		durationProp = isTriggered ? 0.5 : 0.7;
	} else if (variant === "secondary") {
		initialProp = { y: "150%" };
		animateProp = { y: isTriggered ? "0%" : "200%" };
		delayProp = isTriggered ? 0.2 : 0;
		durationProp = isTriggered ? 0.7 : 0.5;
	}

	return {
		initial: initialProp,
		animate: animateProp,
		transition: {
			delay: delayProp,
			duration: durationProp,
			ease: "circOut",
		},
	};
};

function Header() {
	const scrollData = useContext(ScrollEventsContext);
	const [usingSecondary, setUsingSecondary] = useState(false);
	const { pathname } = useLocation();
	const { isPreloaderComplete } = useContext(PreloaderContext);

	useEffect(() => {
		if (scrollData) {
			const scrollThreshold = scrollData.y > scrollData.viewportHeight * 0.15;
			if (usingSecondary !== scrollThreshold) {
				setUsingSecondary(scrollThreshold);
			}
		}
	}, [scrollData]);

	return (
		<motion.section
			className={styles.header__wrapper}
			onClick={() => pathname !== "/" && navigate("/")}
			{...headerTransitions(isPreloaderComplete)}
		>
			<motion.div
				className={styles["header__text--primary"]}
				{...animation("primary", usingSecondary)}
			>
				Shawn Lim Wee Kiang
			</motion.div>
			<motion.div
				className={styles["header__text--secondary"]}
				{...animation("secondary", usingSecondary)}
			>
				wklsh.
			</motion.div>
		</motion.section>
	);
}

Header.propTypes = propTypes;

export default Header;
