import React, { createContext, useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

export const MouseEventsContext = createContext();
export const ScrollEventsContext = createContext();

const propTypes = {
	mainRef: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export function ContextWrappers({ mainRef, children }) {
	const mousePosRef = useRef();
	const scrollDataRef = useRef();
	const hasScrolled = useRef(false);
	const [mousePos, setMousePos] = useState();
	const [scrollData, setScrollData] = useState();

	const rafTick = () => {
		// document height changes as the DOM progressively loads.
		// Before any scroll event occurs,
		// actively update the ref whenever the value is not in sync
		if (!hasScrolled.current && mainRef.current) {
			const mainRefWidth = mainRef.current.scrollWidth;
			const mainRefHeight = mainRef.current.scrollHeight;

			if (scrollDataRef.current.documentWidth !== mainRefWidth) {
				scrollDataRef.current = {
					...scrollDataRef.current,
					documentWidth: mainRefWidth,
				};
			}

			if (scrollDataRef.current.documentHeight !== mainRefHeight) {
				scrollDataRef.current = {
					...scrollDataRef.current,
					documentHeight: mainRefHeight,
				};
			}
		}

		setMousePos(mousePosRef.current);
		setScrollData(scrollDataRef.current);

		requestAnimationFrame(rafTick);
	};

	const updateMousePos = ({ clientX, clientY }) => {
		mousePosRef.current = { x: clientX, y: clientY };
	};

	const updateScroll = (evt) => {
		const {
			target: {
				clientWidth: viewportWidth,
				clientHeight: viewportHeight,
				scrollWidth: documentWidth,
				scrollHeight: documentHeight,
				scrollTop: y,
				scrollLeft: x,
			},
		} = evt;

		hasScrolled.current = true;

		scrollDataRef.current = {
			viewportWidth,
			viewportHeight,
			documentWidth,
			documentHeight,
			x,
			y,
		};
	};

	useEffect(() => {
		requestAnimationFrame(rafTick);
		document.addEventListener("mousemove", updateMousePos, { passive: true });

		if (mainRef.current) {
			mainRef.current.addEventListener("scroll", updateScroll, { passive: true });
		}

		if (typeof window !== "undefined") {
			// update refs asap
			scrollDataRef.current = {
				...scrollDataRef.current,
				viewportWidth: window.innerWidth,
				viewportHeight: window.innerHeight,
			};
		}

		return () => {
			document.removeEventListener("mousemove", updateMousePos);

			if (mainRef.current) {
				mainRef.current.removeEventListener("scroll", updateScroll);
			}
			cancelAnimationFrame(rafTick);
		};
	}, []);

	return (
		<MouseEventsContext.Provider value={mousePos}>
			<ScrollEventsContext.Provider value={scrollData}>{children}</ScrollEventsContext.Provider>
		</MouseEventsContext.Provider>
	);
}

ContextWrappers.propTypes = propTypes;
