import React, { useRef } from "react";
import { useFrame, useThree, extend } from "react-three-fiber";
import { BrightnessContrastShader } from "three/examples/jsm/shaders/BrightnessContrastShader";
import { HueSaturationShader } from "three/examples/jsm/shaders/HueSaturationShader";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";

// Makes these prototypes available as "native" jsx-string elements
extend({ EffectComposer, ShaderPass, RenderPass, BrightnessContrastShader, HueSaturationShader });

export default function Effect() {
	const composerRef = useRef();
	const { scene, gl, camera } = useThree();

	useFrame(() => composerRef.current.render(), 1);

	return (
		<effectComposer ref={composerRef} args={[gl]}>
			<renderPass attachArray="passes" scene={scene} camera={camera} />
			<shaderPass
				attachArray="passes"
				args={[BrightnessContrastShader]}
				uniforms-brightness-value={0.145}
				uniforms-contrast-value={0.05}
				renderToScreen
			/>
			<shaderPass
				attachArray="passes"
				args={[HueSaturationShader]}
				uniforms-hue-value={-0.025}
				uniforms-saturation-value={0.044}
				renderToScreen
			/>
		</effectComposer>
	);
}
