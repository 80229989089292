import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { throttle, debounce } from "throttle-debounce";
import { motion } from "framer-motion";
import { has } from "lodash";

import { ScrollEventsContext } from "../ContextWrappers/ContextWrappers";
import { PreloaderContext } from "../Preloader/Preloader";
import { lerp, isNear, isMobile, headerTransitions } from "../../utils";

import styles from "./Scrollbar.module.scss";

const propTypes = {
	mainRef: PropTypes.object.isRequired,
	contentRef: PropTypes.object.isRequired,
};

function Scrollbar({ mainRef, contentRef }) {
	// SR escape
	if (typeof window === "undefined") return null;

	const scrollEvt = useContext(ScrollEventsContext);
	const { isPreloaderComplete } = useContext(PreloaderContext);
	const MAIN_BAR_HEIGHT = "175";
	const RANGE_VALUE = 40;
	const DRAG_MULTIPLIER = 0.6;
	const scrollbarWrapperRef = useRef();
	const scrollbarRef = useRef();
	const originalMousePosY = useRef(null);
	const isHoldingDownRef = useRef(false);
	const dragDistRef = useRef(null);
	const dragDirectionRef = useRef(null);
	const isLerping = useRef(false);
	const clientYRef = useRef(null);
	const isWithinRangeRef = useRef(false);
	const [isWithinRange, setIsWithinRange] = useState(false);
	const [isSeeking, setIsSeeking] = useState(false);
	const [allowTransitions, setAllowTransitions] = useState(true);
	const hasMounted = useRef(false);

	// disable scrolling if lerp is in progress
	if (mainRef.current) {
		mainRef.current.onwheel = (e) => {
			if (isLerping.current) {
				e.preventDefault();
			}
		};
	}

	const disableSelect = (event) => {
		event.preventDefault();
	};

	const debounceResetOGMousePosY = debounce(60, (clientY) => {
		originalMousePosY.current = clientY;
	});

	// only triggers if mouse is currently held down, and cursor is within range
	const mouseMoveFuncThrottled = throttle(30, true, (clientX, clientY) => {
		if (
			isHoldingDownRef.current &&
			isNear(scrollbarWrapperRef.current, clientX, clientY, RANGE_VALUE)
		) {
			const currentScrollBarPosY = scrollbarRef.current.getBoundingClientRect().y;

			dragDirectionRef.current = clientY - currentScrollBarPosY > 0 ? "down" : "up";
			dragDistRef.current = (clientY - originalMousePosY.current) * DRAG_MULTIPLIER;
		}
	});

	const isNearThrottled = throttle(60, true, (el, clientX, clientY) => {
		if (isNear(el, clientX, clientY, RANGE_VALUE)) {
			setIsWithinRange(true);
			isWithinRangeRef.current = true;
		} else {
			setIsWithinRange(false);
			isWithinRangeRef.current = false;
		}
	});

	const mouseActionsListener = (evt) => {
		const { type, clientX, clientY } = evt;

		clientYRef.current = clientY;

		if (type === "mousedown" && isWithinRangeRef.current) {
			isHoldingDownRef.current = true;
			window.addEventListener("selectstart", disableSelect);
			setIsSeeking(true);

			// get mouse pos on initial OnClick
			originalMousePosY.current = clientY;
		}

		if (type === "mousemove") {
			mouseMoveFuncThrottled(clientX, clientY);
			debounceResetOGMousePosY(clientY);
			isNearThrottled(scrollbarWrapperRef.current, clientX, clientY);
		}

		if (type === "mouseup") {
			isHoldingDownRef.current = false;
			dragDirectionRef.current = null;
			window.removeEventListener("selectstart", disableSelect);
			setIsSeeking(false);
		}
	};

	const ticker = () => {
		if (isMobile()) return null;

		mainRef.current.scrollTop = lerp(
			mainRef.current.scrollTop,
			mainRef.current.scrollTop + dragDistRef.current,
			0.5,
		);

		if (dragDistRef.current > -2 && dragDistRef.current < 2) {
			// reset to 0 else it keeps jumping around due to float value
			dragDistRef.current = 0;
			if (isLerping.current) setAllowTransitions(true);
			// reset other flags
			isLerping.current = false;
			dragDirectionRef.current = null;
		} else {
			// tween down to base val
			// Conditions:
			// - if current scroll position of the site is 0 or at the bottom of the page, set to 0
			// - if not, incrementally increase / reduce dragDistRef on every pass through
			if (
				mainRef.current.scrollTop !== 0 &&
				mainRef.current.scrollTop + window.innerHeight <
					contentRef.current.getBoundingClientRect().height * 0.97
			) {
				if (!isLerping.current) setAllowTransitions(false);
				isLerping.current = true;
				dragDistRef.current =
					dragDistRef.current > 0 ? (dragDistRef.current -= 1.25) : (dragDistRef.current += 1.25);
			} else {
				dragDistRef.current = 0;
			}
		}

		return requestAnimationFrame(ticker);
	};

	const calcScrollBarHeight = () => {
		if (has(scrollEvt, "documentHeight") && has(scrollEvt, "viewportHeight")) {
			return (scrollEvt.viewportHeight / scrollEvt.documentHeight) * MAIN_BAR_HEIGHT;
		}

		return null;
	};

	const calcActiveBarPosY = () => {
		if (has(scrollEvt, "y") && has(scrollEvt, "documentHeight")) {
			return (scrollEvt.y / scrollEvt.documentHeight) * 100;
		}

		return null;
	};

	useEffect(() => {
		if (!isMobile()) {
			window.addEventListener("mouseup", mouseActionsListener, { passive: true });
			window.addEventListener("mousedown", mouseActionsListener, { passive: true });
			window.addEventListener("mousemove", mouseActionsListener, { passive: true });
			requestAnimationFrame(ticker);
			hasMounted.current = true;
		}

		return () => {
			cancelAnimationFrame(ticker);
		};
	}, []);

	return hasMounted.current ? (
		<motion.span {...headerTransitions(isPreloaderComplete, 1.5)}>
			<motion.section
				ref={scrollbarWrapperRef}
				className={styles.progress__wrapper}
				style={{ height: `${MAIN_BAR_HEIGHT}px` }}
				initial={false}
				animate={{
					opacity: isWithinRange ? 1 : 0.5,
				}}
				transition={{
					scaleY: {
						duration: 0.6,
					},
					opacity: {
						ease: "circOut",
						duration: 1,
					},
				}}
			>
				<div
					ref={scrollbarRef}
					className={`${styles.progress__activeRangeBar} ${
						allowTransitions ? styles[`progress__activeRangeBar--allowTransitions`] : null
					}`}
					style={{
						height: `${calcScrollBarHeight()}px`,
						top: `${calcActiveBarPosY()}%`,
					}}
				>
					<motion.span
						className={styles.progress__arrowUp}
						initial={false}
						animate={{
							opacity: isWithinRange ? 1 : 0,
							y: isWithinRange ? (isSeeking ? 3 : -2) : -10,
						}}
						transition={{
							ease: "circOut",
							opacity: 0.3,
							y: 0.4,
						}}
					/>
					<motion.span
						className={styles.progress__arrowDown}
						initial={false}
						animate={{
							opacity: isWithinRange ? 1 : 0,
							y: isWithinRange ? (isSeeking ? -3 : 2) : 10,
						}}
						transition={{
							ease: "circOut",
							opacity: 0.3,
							y: 0.4,
						}}
					/>
				</div>
			</motion.section>
		</motion.span>
	) : null;
}

Scrollbar.propTypes = propTypes;

export default Scrollbar;
