function lerpEase(t) {
	let time = t;

	const ease = (time -= 1) * time * time + 1;
	return ease;
}

/**
 * Linear interpolation function
 * @param {num} start   FROM value
 * @param {num} end     TO value
 * @param {num} amt     steps
 */
export function lerp(start, end, amt) {
	// return (1 - amt) * start + amt * end;
	return start + (end - start) * lerpEase(amt);
}

/**
 * Return boolean to determine if mousePos is near target element
 * @param {obj} element   target element
 * @param {num} mouseX    mouse pos X value
 * @param {num} mouseY    mouse pos Y value
 * @param {num} distance  threshold radius
 */
export function isNear(element, mouseX, mouseY, distance) {
	if (!element) return null;

	const elDimensions = element.getBoundingClientRect();

	const left = elDimensions.left - distance;
	const top = elDimensions.top - distance;
	const right = left + elDimensions.width + 2 * distance;
	const bottom = top + elDimensions.height + 2 * distance;

	return mouseX > left && mouseY < right && mouseY > top && mouseY < bottom;
}
