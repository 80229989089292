/* eslint-disable no-console */

import React, { useEffect, useState, createContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import { get } from "lodash";
import { useLocation } from "@reach/router";
import uniqid from "uniqid";

import styles from "./Preloader.module.scss";

const propTypes = {
	children: PropTypes.object.isRequired,
	CMSPreviewMode: PropTypes.bool.isRequired,
};

export const PreloaderContext = createContext();

/**
 * Preloader function
 * @param {arr} array         images to load
 * @param {func} callback     callback function on complete / failure
 * @param {func} onProgress   callback on every file load completion
 */
const preloadImages = (
	array,
	callback = () => console.log("Default Preloader Loaded Message"),
	onProgress = () => {},
) => {
	if (!Array.isArray(array)) {
		console.warn("preloader did not receive an array, proceeding with callback nonetheless");
		if (typeof callback !== "function") {
			console.warn("preloader has no callback too, WTF? Returning null");
			return null;
		}

		callback();
		return null;
	}

	let hasLoaded = 0;
	let hasRejected = 0;
	array.forEach(async (itemSrc) => {
		const promise = new Promise((resolve, reject) => {
			const image = new Image();
			image.onload = () => resolve(itemSrc);
			image.onerror = () => {
				console.error("Image load has been rejected –", itemSrc);
				hasRejected += 1;
				reject(itemSrc);
			};
			image.src = itemSrc;
		});

		if (await promise) {
			hasLoaded += 1;

			onProgress(hasLoaded / array.length);

			if (hasLoaded + hasRejected === array.length) {
				callback();
			}
		}
	});

	return null;
};

/**
 * format percent string
 * @param {str} pct     percent value
 * @param {str} index   index from the back
 */
const formatPercent = (pct, index = 0) => {
	const pctStr = Math.round(pct).toString();
	return pctStr.substring(pctStr.length - 1 - index, pctStr.length - index) || 0;
};

const tween = {
	initial: {
		y: -8,
		opacity: 0,
	},
	animate: {
		y: 0,
		opacity: 1,
		transition: {
			duration: 0.5,
			ease: [0.25, 1, 0.5, 1],
		},
	},
	exit: {
		y: 3,
		opacity: 0,
		transition: {
			duration: 0.5,
			ease: [0.25, 1, 0.5, 1],
		},
	},
	transition: {
		duration: 0.4,
		transition: {
			duration: 0.5,
			ease: [0.25, 1, 0.5, 1],
		},
	},
};

function Preloader({ children, CMSPreviewMode }) {
	if (CMSPreviewMode) return children;

	const { origin } = useLocation();
	const [hasPreloaded, setHasPreloaded] = useState(false);
	const [hasBackgroundLoaded, setHasBackgroundLoaded] = useState(false);
	const [loadProgress, setLoadProgress] = useState(0);
	const [percent, setPercent] = useState(0);
	const [percentOnes, setPercentOnes] = useState(0);
	const [percentTens, setPercentTens] = useState(0);
	const [percentHundreds, setPercentHundreds] = useState(0);
	const [preloaderAnimComplete, setPreloaderAnimComplete] = useState(false);

	const data = useStaticQuery(graphql`
		query {
			allFile(filter: { extension: { regex: "/gif|jpe?g|tiff|png|webp|bmp/" } }) {
				edges {
					node {
						publicURL
					}
				}
			}
		}
	`);

	const images = get(data, "allFile.edges").map((i) => {
		return `${origin}${get(i, "node.publicURL")}`;
	});

	useEffect(() => {
		// preloadImages(
		// 	images,
		// 	() => setHasPreloaded(true),
		// 	(progress) => setLoadProgress(progress),
		// );

		// FORCE-OVERWRITE
		setHasPreloaded(true);
		setPreloaderAnimComplete(true);
	}, []);

	useEffect(() => {
		// since we cant obtain file size of images directly,
		// assign background to a fixed 30%, and append it whenever its done loading
		setPercent(loadProgress * 70 + (hasBackgroundLoaded ? 30 : 0));
	}, [loadProgress]);

	useEffect(() => {
		setPercentHundreds(formatPercent(percent, 2));
		setPercentTens(formatPercent(percent, 1));
		setPercentOnes(formatPercent(percent, 0));
	}, [percent]);

	return (
		<PreloaderContext.Provider
			value={{
				isPreloaderComplete: preloaderAnimComplete,
				action: setHasBackgroundLoaded,
			}}
		>
			{!hasBackgroundLoaded || !hasPreloaded || !preloaderAnimComplete ? (
				<section className={styles.wrapper__main}>
					<motion.div
						className={styles.backdrop}
						initial={{ scaleY: 1 }}
						animate={{ scaleY: percent === 100 ? 0 : 1 }}
						style={{ originY: 0 }}
						transition={{ delay: 2.5, duration: 0.8, ease: [0.33, 1, 0.68, 1] }}
						onAnimationComplete={() => {
							setPreloaderAnimComplete(true);
						}}
					/>
					<motion.div
						className={styles.numeral__wrapperMain}
						initial={{ y: 0, opacity: 1 }}
						animate={{
							opacity: percent === 100 ? 0 : 1,
							y: percent === 100 ? 8 : 0,
						}}
						transition={{ delay: 2, duration: 0.45 }}
					>
						{/* <AnimatePresence exitBeforeEnter>
							<motion.div key={percentHundreds} className={styles.numeral__item} {...tween}>
								{percentHundreds}
							</motion.div>
						</AnimatePresence>

						<AnimatePresence exitBeforeEnter>
							<motion.div key={percentTens} className={styles.numeral__item} {...tween}>
								{percentTens}
							</motion.div>
						</AnimatePresence>

						<AnimatePresence exitBeforeEnter>
							<motion.div key={percentOnes} className={styles.numeral__item} {...tween}>
								{percentOnes}
							</motion.div>
						</AnimatePresence> */}

						<AnimatePresence exitBeforeEnter>
							<motion.div key={percentOnes} className={styles.numeral__item} {...tween}>
								Loading...
							</motion.div>
						</AnimatePresence>
					</motion.div>
				</section>
			) : null}

			{children}
		</PreloaderContext.Provider>
	);
}

Preloader.propTypes = propTypes;

export default Preloader;
