/**
 * Prepend leading zeros to a string(numeral) value
 * @param {string} str        string to prepend
 * @param {number} length     length of string required
 */
export function leadingZero(str, length = 2) {
	let string = str.toString();

	if (string && string.length < length) {
		for (let i = string.length; i < length; i += 1) {
			string = `0${string}`;
		}
	}

	return string;
}

/**
 * Format query data line breaks into separate array items
 * @param {string} str  string to format
 * @returns             array
 */
export function formatLineBreaks(str) {
	if (str) {
		return str.split(/\r?\n/);
	}

	return str;
}
