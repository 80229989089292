import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useFrame } from "react-three-fiber";
import * as THREE from "three";
import { Noise } from "noisejs";

import { determinePlaneSize, mouseFromCenter } from "./threeHelpers";
import { lerp } from "../../utils";

import nxImg from "./assets/bgAsset-nx.jpg";
import nyImg from "./assets/bgAsset-ny.jpg";
import nzImg from "./assets/bgAsset-nz.jpg";
import pxImg from "./assets/bgAsset-px.jpg";
import pyImg from "./assets/bgAsset-py.jpg";
import pzImg from "./assets/bgAsset-pz.jpg";

const noise = new Noise(Math.random());

const propTypes = {
	mouse: PropTypes.object.isRequired,
	isPreloaderComplete: PropTypes.bool.isRequired,
	updatePreloaderAfterMapLoads: PropTypes.func.isRequired,
};

function Plane({ mouse, isPreloaderComplete, updatePreloaderAfterMapLoads }) {
	const meshRef = useRef();
	const planeRef = useRef();
	const matRef = useRef();
	const [envMap, setEnvMap] = useState(null);
	const onboardingComplete = useRef(false);
	const meshPosZ = -180;

	useEffect(() => {
		new THREE.CubeTextureLoader().load([nxImg, nyImg, nzImg, pxImg, pyImg, pzImg], (map) => {
			map.encoding = THREE.sRGBEncoding;
			setEnvMap(map);
			updatePreloaderAfterMapLoads(true);
		});
	}, []);

	const geometryAttributes = {
		x: 24,
		y: 34,
		timeX: 0.15,
		timeY: 0.3,
		multiplier: 35,
	};

	const materialAttributes = {
		color: "#000",
		emissive: "#000",
		metalness: 1.24,
		envMapIntensity: 11,
		roughness: 0.03,
	};

	const mWaveMultiplier = {
		intensity: 1,
		segments: 2.5,
		subFragSize: 0.18,

		// intensity: 1.25,
		// segments: 1.5,
		// subFragSize: 0.05,
	};

	const moveRangeX = 9;
	const moveRangeY = 4;
	const rotateRangeX = 4;
	const rotateRangeY = 4.5;
	const lerpAmt = 0.1;

	useFrame(({ clock }) => {
		if (!planeRef.current) return;

		meshRef.current.rotation.z = (225 * Math.PI) / 180;

		// mouse interactions
		meshRef.current.position.x = lerp(
			meshRef.current.position.x,
			moveRangeX * mouseFromCenter(mouse.current).x,
			lerpAmt,
		);
		meshRef.current.position.y = lerp(
			meshRef.current.position.y,
			moveRangeY * mouseFromCenter(mouse.current).y,
			lerpAmt,
		);

		// onboarding animation lerp
		if (isPreloaderComplete) {
			meshRef.current.position.z = lerp(meshRef.current.position.z, meshPosZ, 0.0075);

			if (Math.round(meshRef.current.position.z) === meshPosZ && !onboardingComplete.current) {
				onboardingComplete.current = true;
			}
		}

		meshRef.current.rotation.x = lerp(
			meshRef.current.rotation.x,
			(rotateRangeY * mouseFromCenter(mouse.current).y * Math.PI) / 180,
			lerpAmt,
		);
		meshRef.current.rotation.y = lerp(
			meshRef.current.rotation.y,
			(rotateRangeX * mouseFromCenter(mouse.current).x * Math.PI) / 180,
			lerpAmt,
		);

		const planeGeometry = planeRef.current;
		const vertices = planeGeometry.attributes.position.array;
		const time = clock.elapsedTime;
		const sinTime = (time + Math.sin(time * 1.55)) * 0.15;
		const offsetTimeXSwing = Math.cos(time * 0.5) * -0.25;
		const offsetTimeYSwing = Math.cos(time * 0.25) * 0.05;
		const offsetTimeX = -((time + sinTime) * geometryAttributes.timeX + offsetTimeXSwing);
		const offsetTimeY = -((time + sinTime) * geometryAttributes.timeY + offsetTimeYSwing);
		const protrudeMin = 0.85;
		const protrudeMax = 3.25;

		let sinProtrude = 0.75 + Math.sin(time * 0.5);
		if (sinProtrude < protrudeMin) {
			sinProtrude = protrudeMin;
		} else if (sinProtrude > protrudeMax) {
			sinProtrude = protrudeMax;
		}

		for (let i = 0; i < vertices.length; i += 3) {
			const x = vertices[i];
			const y = vertices[i + 1];
			const z = vertices[i + 2];
			planeGeometry.dispose();
			const subNoise =
				100 +
				noise.perlin2((y + time) * mWaveMultiplier.subFragSize, z * mWaveMultiplier.subFragSize) *
					(Math.abs(Math.sin(time * 0.6)) + 0.2) *
					0.04;
			vertices[i + 2] =
				noise.perlin2(
					x / (geometryAttributes.x * mWaveMultiplier.segments) + subNoise + offsetTimeX,
					y / (geometryAttributes.y * mWaveMultiplier.segments) + offsetTimeY,
				) *
				sinProtrude *
				geometryAttributes.multiplier *
				mWaveMultiplier.intensity;
		}

		planeGeometry.attributes.position.needsUpdate = true;
		planeGeometry.computeVertexNormals();
	});

	return (
		<mesh
			ref={meshRef}
			position={[
				moveRangeX * mouseFromCenter(mouse.current).x,
				moveRangeY * mouseFromCenter(mouse.current).y,
				onboardingComplete.current ? meshPosZ : meshPosZ * 2,
			]}
		>
			{envMap && (
				<>
					<planeBufferGeometry
						ref={planeRef}
						attach="geometry"
						args={[
							determinePlaneSize().dimensions,
							determinePlaneSize().dimensions,
							determinePlaneSize().segments,
							determinePlaneSize().segments,
						]}
					/>

					<meshStandardMaterial
						ref={matRef}
						attach="material"
						color={materialAttributes.color}
						emissive={materialAttributes.emissive}
						metalness={materialAttributes.metalness}
						envMapIntensity={materialAttributes.envMapIntensity}
						roughness={materialAttributes.roughness}
						envMap={envMap}
					/>
				</>
			)}
		</mesh>
	);
}

Plane.propTypes = propTypes;

export default Plane;
