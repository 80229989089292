import React, { useRef, useEffect, useContext } from "react";
import { Canvas } from "react-three-fiber";

import Plane from "./Plane";
import Effect from "./Effect";
import { PreloaderContext } from "../Preloader/Preloader";

function Background() {
	const { isPreloaderComplete, action: preloaderAction } = useContext(PreloaderContext);

	const mouse = useRef({ x: 0, y: 0 });

	const updateMouseCoords = ({ clientX, clientY }) => {
		mouse.current = { x: clientX, y: clientY };
	};

	useEffect(() => {
		if (typeof window !== "undefined") {
			document.addEventListener("mousemove", updateMouseCoords, { passive: true });
		}

		return () => {
			if (typeof window !== "undefined") {
				document.removeEventListener("mousemove", updateMouseCoords);
			}
		};
	}, []);

	return (
		<Canvas
			onCreated={({ gl }) => {
				gl.toneMappingExposure = 350;
			}}
			gl={{ alpha: false }}
			camera={{
				position: [0, 0, 30],
				rotation: [0, 0, Math.PI],
			}}
		>
			<fog attach="fog" color="#000" near={30} far={225} />
			<Plane
				mouse={mouse}
				isPreloaderComplete={isPreloaderComplete}
				isPreloaderComplete={true}
				updatePreloaderAfterMapLoads={preloaderAction}
			/>
			<Effect />
		</Canvas>
	);
}

export default Background;
